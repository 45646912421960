<template lang="pug">
main(data-view)
  h3 Nos programmes
  h1 Choisissez le programme qui vous intéresse
  .courses-wrapper
    course-abstract-card(v-for='(course, index) in courses', :course='course', :key='`course:${index}`', withShadow)
</template>

<script>
export default {
  name: 'Courses',
  components: {
    CourseAbstractCard: () => import('@/components/CourseAbstractCard')
  },
  metaInfo() {
    return {
      title: 'Choisissez votre programme',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: "Faites votre choix parmi la liste de programmes d'auto-coaching que propose Blast !"
        }
      ]
    }
  },
  data() {
    return {
      courses: []
    }
  },
  created() {
    this.getAllCourses()
  },
  methods: {
    async getAllCourses() {
      const response = await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'course'))
      this.courses = response.results.sort((a, b) => parseInt(a.data.order) - parseInt(b.data.order))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

h1 {
  font-size: 1.5rem;
  margin-bottom: 3rem;

  @include medium-screen {
    font-size: 1.8rem;
  }
  @include large-screen {
    font-size: 2rem;
  }
}

h3 {
  color: $orange;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 1rem;

  @include medium-screen {
    font-size: 1.5rem;
  }
  @include large-screen {
    font-size: 1.8rem;
  }
}

.courses-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: -1rem;
  margin-bottom: 5rem;
}
</style>
